import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import React, { lazy, Suspense, useEffect } from 'react'
import { Provider, useDispatch } from 'react-redux'
import store from '../redux'
import { login, logout } from '../redux/user/actions'
import Authorized from './Authorized'
import { Route } from 'react-router-dom'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import {
  $SUCCESS_COLOR,
  $ERROR_COLOR,
  $Link_COLOR,
  $WARN_COLOR,
} from '@/constants/styles'
import { useFetchCountryList } from '@/redux/country/reducers'

const Account = lazy(() => import('../views/account'))
const ResetPassword = lazy(
  () => import('../views/account/reset-password/ResetPassword'),
)
const Register = lazy(() => import('../views/account/register/Register'))
// 时间日期格式化
moment.locale('zh-cn')

export { store }

const GlobalStyle = createGlobalStyle`
  #root .nc-container .nc_scale span {
    height: 100%;
  }
`

const App = () => {
  const dispatch = useDispatch()
  // 请求国家列表
 useFetchCountryList()
  useEffect(() => {
    const token = localStorage.getItem('token')
    token ? dispatch(login(token)) : dispatch(logout())
  }, [dispatch])

  return (
    <ConfigProvider locale={zhCN}>
      <GlobalStyle />
      <Router>
        <Suspense fallback={null}>
          <Switch>
            <Route path="/auth" exact>
              <Account />
            </Route>
            <Route path="/reset-password" exact>
              <ResetPassword />
            </Route>
            <Route path="/register" exact>
              <Register />
            </Route>
            <Authorized />
          </Switch>
        </Suspense>
      </Router>
    </ConfigProvider>
  )
}

type themeColor = 'success' | 'error' | 'link' | 'warn'
export type ThemeField = {
  [key in themeColor as `${key}Color`]: string
}
const theme: ThemeField = {
  successColor: $SUCCESS_COLOR,
  errorColor: $ERROR_COLOR,
  linkColor: $Link_COLOR,
  warnColor: $WARN_COLOR,
}
const ConnectReduxApp = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>
)

export default ConnectReduxApp
